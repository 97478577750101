import {
  HttpCommon,
  HttpCommonUrlMdm
} from "./http-common";
import {
  addTrace
} from "./logging";

import Vue from "vue";
import i18n from "@/locale/i18n";
import Swal from 'sweetalert2';


// get appconfig data from localstorage
const AppConfig = JSON.parse(localStorage.getItem("AppConfig"));

// get api version and set data in sessionstorage from response
export const versionApi = (callback) => {

  addTrace("Call API to get API Version", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    // error message text in default language
    let messageText='';
    if(i18n.locale === 'en'){
      messageText = 'Your user is not assigned to any application role required to use this application. If you need access to this application please contact your administrator.'
    }else if (i18n.locale === 'de'){
      messageText = 'Ihr Benutzer ist keiner Anwendungsrolle zugewiesen, die für die Verwendung dieser Anwendung erforderlich ist. Wenn Sie Zugriff auf diese Anwendung benötigen, wenden Sie sich bitte an Ihren Administrator.'
    }else if (i18n.locale === 'fr'){
      messageText = `Votre utilisateur n'est affecté à aucun rôle d'application requis pour utiliser cette application. Si vous avez besoin d'accéder à cette application, veuillez contacter votre administrateur.`
    }
    HttpCommon.get("/v1/infos/version")
      .then((response) => {
        let data = response.data;
        if (!response.data.userRoleText) {
          let messageError = i18n.t("80070.LongText");
          messageError =
            messageError == "80070.LongText" ?
            messageText :
            messageError;
            Swal.fire({
              title: 'Confirm!',
              text: messageError,
              type: 'warning',
              confirmButtonColor: '#f8a31e',
              confirmButtonText: 'Ok',
              buttonsStyling: true,
              allowOutsideClick: false
            }).then(isConfirm=>{
              if(isConfirm.value==true){
              sessionStorage.removeItem("token");
              addTrace(messageError, 4);
              window.location.href = AppConfig.Permission_Fallback_Url;
              }
            })
          if (callback) return callback(false);
        } else {
          sessionStorage.setItem("apiVersion", data.versionNo);
          sessionStorage.setItem("emailAddress", data.userId);
          sessionStorage.setItem("userRoleText", data.userRoleText);
          sessionStorage.setItem("userRoleNo", data.userRoleNo);
          addTrace("API version received successfully!", 4);
          addTrace("User role successfully retrieved!", 4);
          verifyTanent(function (res) {
            if (res) {
              if (callback) return callback(true);
            } else {
              if (callback) return callback(false);
            }
          });
        }
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
  }else {
    if (callback) return callback(false);
  }
};

// verify tenant and if not verify then desiplay message 
export const verifyTanent = (callback) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    // error message text in default language
    let messageText='';
    if(i18n.locale === 'en'){
      messageText = 'This application is running for the tenant %1. If you need to have access to this tenant, please contact your administrator.'
    }else if (i18n.locale === 'de'){
      messageText = ' Diese Anwendung wird für den Mandanten %1 ausgeführt. Wenn Sie Zugriff auf diesen Mandanten benötigen, wenden Sie sich bitte an Ihren Administrator.'
    }else if (i18n.locale === 'fr'){
      messageText = `Cette application est en cours d'exécution pour le locataire %1. Si vous devez avoir accès à ce locataire, veuillez contacter votre administrateur.`
    }
    HttpCommonUrlMdm.get("/v1/token/tenantsAvailable")
      .then((response) => {
        var obj = response?.data.find(element => element == AppConfig.Tenant)
        let valid = false;
        if (!obj) {
          let messageError = i18n.t("80089.LongText");
          messageError =
            messageError == "80089.LongText" ?
            messageText :
            messageError;
          messageError = messageError.replace("%1", AppConfig.Tenant);
          Swal.fire({
            title: 'Confirm!',
            text: messageError,
            type: 'warning',
            confirmButtonColor: '#f8a31e',
            confirmButtonText: 'Ok',
            buttonsStyling: true,
            allowOutsideClick: false
          }).then((isConfirm) => {
            if (isConfirm.value === true) {
              sessionStorage.removeItem("token");
              valid = false;
              addTrace(messageError, 4);
              window.location.href = AppConfig.Permission_Fallback_Url;
            }
          });
        } else {
          sessionStorage.setItem("tenant", AppConfig.Tenant);
          valid = true;
        }
        if (callback) return callback(valid);
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
  }else {
    if (callback) return callback(false);
  }
};

// if user is authorized then load an application
export const loadApplications = () => {
  addTrace("Call API to load Applications", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonUrlMdm.get("/v2/token/applicationsAvailable")
      .then((response) => {  
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// logout and remove token from sessionstorage
export const logOut = () => {
  const token = sessionStorage.getItem("token");
  HttpCommonUrlMdm.post("/v2/token/invalidate", {
      Token: token
    })
    .then((response) => {
      if (response.data.RedirectUrl) {
        sessionStorage.removeItem("token");
        document.location.href = response.data.RedirectUrl;
      }
    })
    .catch((err) => {
      Vue.notify({
        group: "foo",
        type: "error",
        title: `${err}`,
        duration: 4000,
      });
      addTrace(err, 4);
    });
};