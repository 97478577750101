import axios from "axios";
const AppConfig = JSON.parse(localStorage.getItem("AppConfig"));

// cretae interceptors for http commonlog using AppConfig.UrlApiLogIt
const httpCommonLog = axios.create({
  baseURL: AppConfig.UrlApiLogIt,
  headers: {
    Authorization: "Bearer " + sessionStorage.token,
  },
});
httpCommonLog.interceptors.request.use(
  (req) => {
    req.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
    return req;
  },
  (error) => {
    console.log(`error ${error}`);
    return Promise.reject(error);
  }
);

// cretae interceptors for http common using AppConfig.UrlApi
const httpCommon = axios.create({
  baseURL: AppConfig.UrlApi,
  headers: {
    Authorization: "Bearer " + sessionStorage.token,
  },
});
httpCommon.interceptors.request.use(
  (req) => {
    req.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
    return req;
  },
  (error) => {
    console.log(`error ${error}`);
    return Promise.reject(error);
  }
);

// cretae interceptors for http common anlytics using AppConfig.UrlApiAnalytics
const httpCommonAnalytics = axios.create({
  baseURL: AppConfig.UrlApiAnalytics,
  headers: {
    Authorization: "Bearer " + sessionStorage.token,
  },
});
httpCommonAnalytics.interceptors.request.use(
  (req) => {
    req.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
    return req;
  },
  (error) => {
    console.log(`error ${error}`);
    return Promise.reject(error);
  }
);

// cretae interceptors for http common url for mdm using AppConfig.UrlMdm
const httpCommonUrlMdm = axios.create({
  baseURL: AppConfig.UrlMdm,
  headers: {
    Authorization: "Bearer " + sessionStorage.token,
  },
});
httpCommonUrlMdm.interceptors.request.use(
  (req) => {
    req.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
    return req;
  },
  (error) => {
    console.log(`error ${error}`);
    return Promise.reject(error);
  }
);

// cretae interceptors for http common url for user api using AppConfig.UrlApiUser
const httpCommonUrlApiUser = axios.create({
  baseURL: AppConfig.UrlApiUser,
  headers: {
    Authorization: "Bearer " + sessionStorage.token,
  },
});
httpCommonUrlApiUser.interceptors.request.use(
  (req) => {
    req.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
    return req;
  },
  (error) => {
    console.log(`error ${error}`);
    return Promise.reject(error);
  }
);

// cretae interceptors for http common url for notification using AppConfig.UrlNotification
const httpCommonNotificationUrlApi = axios.create({
  baseURL: AppConfig.UrlNotification,
  headers: {
    Authorization: "Bearer " + sessionStorage.token,
  },
});
httpCommonNotificationUrlApi.interceptors.request.use(
  (req) => {
    req.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
    return req;
  },
  (error) => {
    console.log(`error ${error}`);
    return Promise.reject(error);
  }
);

// export all interceptors to use in other service files
export let HttpCommonLog = httpCommonLog;

export let HttpCommon = httpCommon;

export let HttpCommonAnalytics = httpCommonAnalytics;

export let HttpCommonUrlMdm = httpCommonUrlMdm;

export let HttpCommonUrlApiUser = httpCommonUrlApiUser;

export let HttpCommonNotificationUrlApi = httpCommonNotificationUrlApi;
