import Vue from "vue";
import {
  HttpCommonNotificationUrlApi,
  HttpCommon,
  HttpCommonLog,
  HttpCommonUrlApiUser,
  HttpCommonAnalytics,
} from "./http-common";
import {
  addTrace
} from "./logging";

var $cookie = require("vue-cookie");
import store from "@/store";

// get collaboration details by details
export const loadCollaboration = (config) => {
  addTrace("Collaboration List get", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommon.get("v1/collaboration" + config)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};
// get  all created branch 
export const getAllAvailableCreatedBranch = () => {
  addTrace("Get all avilable Branches", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonUrlApiUser.get("/v1/token/businessEntities")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// get all assigned branch 
export const getAllAvailableAssignedBranch = () => {
  addTrace("Get all avilable Branches", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonLog.get(
        "/v1/mdm/businessEntities?businessEntityType=10019&pageSize=1000"
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// get dyanmic dropdown values by language code  
export const getAllAvailableDynamicDropDowns = (value) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonLog.get(
        "v1/mdm/sysvalues?group=" +
        value +
        "&lang=" +
        ($cookie.get("localeLangCode") ? $cookie.get("localeLangCode") : sessionStorage.getItem("langCode") ? sessionStorage.getItem("langCode") : '10000'))
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};
//get branch function,department and default department from file type
export const getDataFromFileType = (value) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommon.get(
      "v1/masterdata/filetype/" +
        value +
        "/branchfunctions?languageCode=" +
        ($cookie.get("localeLang")
          ? $cookie.get("localeLang")
          : sessionStorage.getItem("localeLang"))
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// get collaboration branch data by department list
export const getAllCollaborationChartData = (obj) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    let arrDepartmentList = "";
    if (obj.department?.length > 0) {
      obj.department.forEach((x) => {
          arrDepartmentList = arrDepartmentList + "arrDepartment=" + x+"&";
      });
    }
    return HttpCommonAnalytics.get(
      "v1/branch/" +
        obj.branchId +
        "/collaboration/" +
        obj.timeaggregation +
        "/" +
        obj.date +
        "/basicmeasures?"+arrDepartmentList+"period="+ obj.NoOfAnalyticsPeriod
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// add collaboration ticket by details 
export const saveCollaboration = (obj) => {
  addTrace("save collaboration", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommon.post("v1/collaboration", obj)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// check subscription status by created branch and assigned branch
export const checkSubscribeStatus = (obj) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonNotificationUrlApi.get("v1/settings/GetSubscribedUsersList/" + obj.createdBranch + "/" + obj.assignedBranch + "/" + obj.department)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// get collaboration details by id
export const loadCollaborationById = (obj) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    var translationCode=obj.translationLanguage.translateLanguageCode==undefined?obj.translationLanguage:obj.translationLanguage.translateLanguageCode
    return HttpCommon.get(
        "v1/collaboration/" +
        obj.id +
        "?translationLanguage="+translationCode+"&languageCode=" +
        ($cookie.get("localeLang") ?
          $cookie.get("localeLang") :
          sessionStorage.getItem("localeLang"))
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 2000,
        });
        addTrace(err, 4);
      });
  }
};

// add reply to ticket by ticket id and details
export const sendReplyMessage = (id, obj) => {
  addTrace("send Collaboration Reply", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommon.post(
        "v1/collaboration/" + id + "/reply",
        obj
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// get branch and department from api
export const getBranchAndDepartment = (callback) => {
  addTrace("Get branch and department", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonNotificationUrlApi.get("/v1/settings")
      .then((response) => {
        if(callback){
        return callback(response);
        }
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
  }else {
    if (callback) return callback(false);
  }
};

// set branch and department from api
export const setBranchAndDepartment = (obj,callback) => {
  addTrace("Get branch and department", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonNotificationUrlApi.put("/v1/settings",obj)
      .then((response) => {
        if(callback){
        return callback(response);
        }
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
  }else{
    if (callback) return callback(false);
  }
};

//get notification type
export const getAllNotificationType = () => {
  addTrace("Get all notification type", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonUrlApiUser.get("/v1/token/NotificationType")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
      });
  }
};

// get User Subscription List from api
export const getUserSubscriptionList = (callback) => {
  addTrace("Get subscription list of user", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonNotificationUrlApi.get("/v1/subscriptions/106")
      .then((response) => {
        if (callback) {
          return callback(response);
        }
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
  }else{
    if (callback) return callback(false);
  }
};

// subscribe branch and department
export const subscribeUnsubscribeBranchAndDepartment = (params, callback) => {
  addTrace("subscribe branch and department", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonNotificationUrlApi.patch("/v1/subscriptions/106/" + params.value, params.obj)
      .then((response) => {
        if (callback) {
          return callback(response);
        }
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
  }else {
    if (callback) return callback(false);
  }
};

//load translation languages
export const loadTranslatorLanguage = () => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommon.get(
      "v1/translator/languages"
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 2000,
        });
        addTrace(err, 4);
      });
  }
};

// decode and verify token
export const decodePluginToken = (parametersetId) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommon.get(
      "v1/plugin/"+parametersetId
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 2000,
        });
        addTrace(err, 4);
      });
  }
};

//get tags suggestions
export const tagsSuggestions = (searchTerm) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommon.get(
      "/v1/collaboration/tags?tags=%23"+searchTerm
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 2000,
        });
        addTrace(err, 4);
      });
  }
};

// language setting for i18n file
export const setLangCode = (callback) => {
  addTrace("Call API to get languages", 4);
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    HttpCommonLog.get("/v1/mdm/sysvalues?group=LangCode")
      .then((response) => {
        if (response.data.length > 0) {
          HttpCommonNotificationUrlApi.get("/v1/settings")
          .then((settingRes) => {
          let languages = [];
          let forLoop;
          forLoop = async function (i) {
            const AppConfig = JSON.parse(localStorage.getItem("AppConfig"));
            if (i < response.data.length) {
              const item = response.data[i];
              let langText = get2String(item.SysValueId);
              setLocaleTexts(item.SysValueNo, langText, function () {
                if (
                  !sessionStorage.getItem("langCode") &&
                  langText == AppConfig.I18N_LOCALE
                ) {
                  sessionStorage.setItem("langCode", item.SysValueNo);
                }
                if (item.SysValueId===settingRes.data.languageCode) {
                  $cookie.set("localeLang", item.SysValueId);
                  $cookie.set("localeLangCode", item.SysValueNo);
                }
                if(item.SysValueId !== 'fr-FR'){
                  languages.push({
                    SysValueId: item.SysValueId,
                    SysValueNo: item.SysValueNo,
                    ShortText: item.TextValues[0].ShortText,
                    LangText: langText,
                  });
                }
                forLoop(i + 1);
              });
            } else {
              sessionStorage.setItem("languages", JSON.stringify(languages));
              store.dispatch("setLanguages", JSON.stringify(languages));

              if (callback) return callback(false);
            }
          };
          forLoop(0);
        })
        }
      })
      .catch((err) => {
        Vue.notify({
          group: "foo",
          type: "error",
          title: `${err}`,
          duration: 4000,
        });
        addTrace(err, 4);
        if (callback) return callback(false);
      });
  }else {
    if (callback) return callback(false);
  }
};

// get consignment number by id
export const getConsignmentMenifestNumber = (objectId) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonLog.get(
      "/v1/consignments/"+objectId
    )
      .then((response) => {
          return response;
      })
      .catch((err) => {
        addTrace(err, 4);
        return false;
      });
  }
};

// get pickup order menifest number by id

export const getPickUpOrderMenifestNumber = (objectId) => {
  if (
    sessionStorage.getItem("token") != null &&
    sessionStorage.getItem("token") != undefined
  ) {
    return HttpCommonLog.get(
      "/v1/pickupOrders/"+objectId
    )
      .then((response) => {
          return response;
      })
      .catch((err) => {
        addTrace(err, 4);
        return false;
      });
  }
};

// get a language code from a string 
function get2String(string) {
  return (string || "").slice(0, 2);
}

// get and store loacl text value in localstorage
function setLocaleTexts(langCode, langText, callback) {
  addTrace(
    [{
      message: "Call API to get TextValues"
    }, {
      language: langText
    }],
    4
  );
  HttpCommonLog.get(
    "/v1/mdm/textValues?objectType=10050&pageSize=10000&lang=" + langCode
  )
    .then((response) => {
      let localTexts = {};
      response.data.map((item) => {
        localTexts[item.ObjectNo] = {
          ShortText: item.ShortText,
          LongText: item.LongText,
        };
      });
      sessionStorage.setItem(
        langText + "-localeTexts",
        JSON.stringify(localTexts)
      );
      if (callback) return callback(false);
    })
    .catch((err) => {
      Vue.notify({
        group: "foo",
        type: "error",
        title: `${err}`,
        duration: 4000,
      });
      addTrace(err, 4);
      if (callback) return callback(false);
    });
}

// end language section